<script lang="ts" setup>
  import { ModalsContainer } from "vue-final-modal";

  const { t } = useI18n();
  const route = useRoute();

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: "id",
    addSeoAttributes: true,
  });
  const title = computed(() => (route.meta.title ? t(route.meta.title) : "Homerunner"));
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <div class="grid layout w-dvw">
        <div class="relative @container" id="header-n-body" style="grid-area: content">
          <div class="grid layout-main relative">
            <slot />
          </div>
        </div>
      </div>
      <ModalsContainer />
    </Body>
  </Html>
</template>

<style scoped>
  .layout {
    grid-template-columns: 1fr;
    grid-template-rows: 100dvh;
    grid-template-areas: "content";
  }

  .layout-main {
    grid-template-columns: 1fr;
    grid-template-rows: 40px calc(100dvh - 40px);
    grid-template-areas: "topbar" "main-content";
  }
</style>
